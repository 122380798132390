<template lang="">
  <input
    type="text"
    @click="cursorHandler"
    v-bind="$attrs"
    @input="inputChangeHandler($event)"
    @blur="$emit('blur', $event)"
    :value="formattedInputValue"
  />
</template>
<script>
/*eslint-disable*/
import { FormatNumber } from '@/services/utils/FormatNumber.js'
export default {
  model: {
    prop: 'inputValue',
    event: 'input',
    default: null
  },
  props: {
    inputValue: {
      type: [String, Number],
      required: true
    },
    maximumDigitsAllowed: {
      type: Number,
      default: 6
    }
  },
  computed: {
    formattedInputValue() {
      if (this.inputValue === '') return ''
      return FormatNumber(this.deFormatToNumber(this.inputValue))
    }
  },
  methods: {
    deFormatToNumber(numberWithCommas) {
      return parseInt(String(numberWithCommas).replaceAll(',', ''))
    },
    cursorHandler(event) {
      if (event.target.selectionStart === 0 && event.target.selectionEnd === 0) {
        const length = event.target.value.length
        event.target.setSelectionRange(length, length)
        event.target.focus()
      }
    },
    inputChangeHandler(event) {
      const matchPattern = /\D/g
      const inputValue = event.target.value
      const currentValue = inputValue.replaceAll(matchPattern, '')
      if (currentValue === '') {
        this.$emit('input', '')
        event.target.value = ''
        return
      }
      if (currentValue.toString().length > this.maximumDigitsAllowed) {
        this.$emit('input', parseInt(this.inputValue))
        event.target.value = FormatNumber(this.inputValue)
      } else {
        this.$emit('input', parseInt(currentValue))
        event.target.value = FormatNumber(currentValue)
      }
    }
  }
}
</script>
