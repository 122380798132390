/*eslint-disable*/
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { CampGoalsAllowedMaximumDigits, MaximumCampInputBound } from '@/constants/CampGoalsMaximumBoundConstants.js'

export default class CampGoalService {
  static isGoalDollar(shortCode) {
    const monetary = [
      GoalShortCodes.ChurchMinistry,
      GoalShortCodes.GideonCard,
      GoalShortCodes.FaithFund,
      GoalShortCodes.BarnabasFund,
      GoalShortCodes.AuxiliaryScripture,
      GoalShortCodes.CampDesignated,
      GoalShortCodes.Receipts
    ]
    return monetary.includes(shortCode)
  }
  static getCampMaximumInputBound(shortCode) {
    const nonDollarGoalsWithMaxFourDigits = [
      GoalShortCodes.GideonCardPresentation,
      GoalShortCodes.GideonCardCollection,
      GoalShortCodes.ChurchPresentation,
      GoalShortCodes.GideonMembership,
      GoalShortCodes.AuxiliaryMembership
    ]

    if (this.isGoalDollar(shortCode)) {
      return CampGoalsAllowedMaximumDigits.CampDollar
    } else if (nonDollarGoalsWithMaxFourDigits.includes(shortCode)) {
      return CampGoalsAllowedMaximumDigits.CampNonDollar
    } else {
      return CampGoalsAllowedMaximumDigits.CampNonDollarSubGoalPlacementsAndPWTs
    }
  }
  static getMaximumTotalAllowed(shortCode) {
    const digit = this.getCampMaximumInputBound(shortCode)
    const resultObj = {
      [CampGoalsAllowedMaximumDigits.CampDollar]: MaximumCampInputBound.Dollar,
      [CampGoalsAllowedMaximumDigits.CampNonDollar]: MaximumCampInputBound.NonDollar,
      [CampGoalsAllowedMaximumDigits.CampNonDollarSubGoalPlacementsAndPWTs]:
        MaximumCampInputBound.NonDollarPlacementsAndPWTs
    }
    return resultObj[digit]
  }
  static prependDollarSign(value) {
    return `$${value}`
  }
}
